import { Fragment } from 'react';
import UserGrid from './UserGrid';
//import UserGridDemo from './UserGridDemo';
import './users.scss';
//import LoadIndicator from 'devextreme-react/load-indicator';
//import { useHttpClient } from '../../contexts/http-hook';
//import { useAuth } from '../../contexts/auth';
//import notify from 'devextreme/ui/notify';

const UsersPage = () => {
  // 	const [loadedUsers, setLoadedUsers] = useState([]);
  //   //const {isLoading, error, sendRequest} = useHttpClient();
  // 	const auth = useAuth();

  //  useEffect(() => {
  //    // BEST PRACTICE -----
  //    // useEffect should not have an async function as a parameter
  //    // instead, create an async function then call it in the useEffect function param.

  //   const fetchUsers = async () => {
  // 		auth.loading = true;
  // 		// Create async fetchUsers function
  // 		try {
  // 			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/all`,{
  // 				method: 'GET',
  // 				body: null,
  // 				headers:{ Authorization: 'Bearer ' + auth.user.token }
  // 		});
  // 		const responseData = await response.json();
  // 		setLoadedUsers(responseData.users)
  // 		auth.loading = false;
  // 	} catch (err) {
  // 		console.log('fetchUsers ERROR:', err.message);
  // 		notify('Error fetching users.', 'error' , 2000);
  // 	};
  //  };
  //  fetchUsers(); //Call  async fetchUsers function

  // },[auth]);

  // 	console.log('loadedUsers:', loadedUsers);
  // 	console.log('auth.loading:', auth.loading);

  return (
    <Fragment>
      <div className={'dx-card responsive-paddings'}>
        <UserGrid />
      </div>
    </Fragment>
  );
};
export default UsersPage;

