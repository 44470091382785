import React from 'react';
import './userdocs.scss';
import DataGrid, { Column } from 'devextreme-react/data-grid';


const UserDocs = () => (
  <React.Fragment>
    <h2 className={'content-block'}>User Guides</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <DataGrid
          //dataSource={downloadData}
          //onRowClick={onRowClick}
          //onDataErrorOccurred={dataErrorOccurred}
        >
          <Column dataField="title" />
          <Column dataField="relDate" caption="Release Date" dataType="date" />
        </DataGrid>
      </div>
    </div>
  </React.Fragment>
);
export default UserDocs;