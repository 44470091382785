import { useMemo, useCallback } from 'react';
import {
  DataGrid,
  Column,
  ColumnFixing,
  FilterRow,
  SearchPanel,
  RequiredRule,
  Editing,
  Popup,
  Form,
  Item,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { useAuth } from '../../contexts/auth';

const URL = process.env.REACT_APP_API_URL;

const SectionGrid = (props) => {

  const auth = useAuth();
  const sendRequest = useCallback(
    async (url, method = 'GET', data = {}) => {
      console.log('SENDREQUEST: ', method, url, data);
      if (method === 'GET') {
        try {
          const result = await fetch(url, {
            method,
            credentials: 'include',
            headers: { Authorization: 'Bearer ' + auth.user.token },
          });
          console.log('sendRequest RESULT');

          const json = await result.json();
          if (result.ok) {
            return json;
          }
          throw json.Message;
        } catch (err) {
          throw err.Message;
        }
      }

      const { key, values } = data;
      let params;
      if (values) {
        // send key + params. for PUT and POST
        params = {
          key,
          ...JSON.parse(values),
        };
      } else {
        // just send key, values are not included for DELETE
        params = {
          key,
        };
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
        body: JSON.stringify(params),
        credentials: 'include',
      });

      console.log('RESPONSE', response);
      const responseData = await response.text();
      console.log('RESPONSE Data', responseData);
      if (!response.ok) {
        throw responseData;
      }
    },
    [auth.user.token]
  );

  const loadHandler = useCallback(() => {
    const result = sendRequest(`${URL}/sections/all`);
    console.log('LOADHANDLER:', result);
    return result;
  }, [sendRequest]);

  const insertHandler = useCallback(
    (values) => {
      return sendRequest(`${URL}/sections/insert`, 'POST', {
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const updateHandler = useCallback(
    (key, values) => {
      return sendRequest(`${URL}/sections/update`, 'PUT', {
        key,
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const removeHandler = useCallback(
    (key) => {
      return sendRequest(`${URL}/sections/delete`, 'DELETE', {
        key,
      });
    },
    [sendRequest]
  );

  const sectionData = useMemo(() => {
    return new CustomStore({
      key: '_id',
      load: loadHandler,
      insert: insertHandler,
      update: updateHandler,
      remove: removeHandler,
    });
  }, [loadHandler, insertHandler, updateHandler, removeHandler]);
  
  return (
    <div className="App">
      <DataGrid
        dataSource={sectionData}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
      >
        {/* 
        menuDesc
        pageHeading
        dispOrder
        tableHeading
        image1
        image2
        memo
        */}
        <Column dataField="dispOrder" width={100}></Column>
        <Column dataField="menuDesc" caption="Description">
          <RequiredRule />
        </Column>
        <Column dataField="pageHeading"></Column>
        <Column dataField="tableHeading"></Column>
        <Column dataField="Image1" visible={false} />
        <Column dataField="Image2" visible={false} />
        <Column dataField="memo" visible={false} />
        <ColumnFixing enabled={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
        >
          <Popup />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="menuDesc" caption="Menu Description:" />
              <Item dataField="pageHeading" />
              <Item dataField="tableHeading" />
              <Item dataField="dispOrder" caption="Display Order" />
              <Item dataField="image1" />
              <Item dataField="image2" />
              <Item dataField="memo" editorType="dxTextArea" colSpan={2} />
            </Item>
          </Form>
        </Editing>
      </DataGrid>
    </div>
  );
};
export default SectionGrid;
