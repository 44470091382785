import React from 'react';
import { Link } from 'react-router-dom';

import './home.scss';

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Home</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div className={'logos-container'}>
            <img
              src={
                'https://dashdev.com/wp-content/uploads/2020/03/Dash-Logo.png'
              }
              alt="Dash Inc."
            />
          </div>
          <h2>DOCUMENT AUTOMATION SOFTWARE</h2>
          <ul>
            <li>
              <div className={'link'}>
                <Link to={'/download'}>Downloads</Link>
              </div>
            </li>
            {/* <li>
              <div className={'link'}>
                <Link to={'/download'}>User Guides</Link>
              </div>
            </li> */}
            {/* <li>
              <div className={'link'}>
                <Link to={'/download'}>Videos</Link>
              </div>
            </li> */}
            <li>
              <a
                href={'https://dashdev.com/knowledge/dashtv/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Informational Videos
              </a>
            </li>
            <li>
              <a
                href={'https://dashdev.com/knowledge/seminars/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Seminars
              </a>
            </li>
          </ul>
          <p>
            <span>
              For technical support related to Dash DDX, feel free to{' '}
            </span>
            <a
              href="mailto:ddxsupport@dashdev.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );}
