export const navigation = [
  {
    text: 'Welcome',
    path: '/welcome',
    access:0,
    icon: 'card'
  },
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    access: 1,
  },
  {
    text: 'Download',
    path: '/download',
    icon: 'download',
    access: 1,
  },
    {
    text: 'User Guides',
    path: '/userdocs',
    icon: 'docfile',
    access: 99
  },

  {
    text: 'Admin',
    icon: 'preferences',
    access: 98,
    items: [
      {
        text: 'Users',
        path: '/users',
        icon: 'group',
        access: 98,
      },
      {
        text: 'Companies',
        path: '/companies',
        icon: 'chart',
        access: 98,
      },
      {
        text: 'Downloads',
        path: '/dl-admin',
        icon: 'download',
        access: 98,
      },
      {
        text: 'Sections',
        path: '/section',
        icon: 'fields',
        access: 98,
      },
    ],
  }
];
