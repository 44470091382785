import { useMemo, useCallback, useState, useRef} from 'react';
import {
  DataGrid,
  Column,
  ColumnFixing,
  SearchPanel,
  RequiredRule,
  Editing,
  Form,
  Item,
  Lookup,
} 
from 'devextreme-react/data-grid';
import 'devextreme-react/html-editor';
import List from 'devextreme-react/list';
//import FileUploader from 'devextreme-react/file-uploader';
import CustomStore from 'devextreme/data/custom_store';
import { useAuth } from '../../contexts/auth';

const URL = process.env.REACT_APP_API_URL;
    

const htmlEditorOptions = {
  height: 290,
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      {
        name: 'font',
        acceptedValues: [
          'Arial',
          'Georgia',
          'Tahoma',
          'Times New Roman',
          'Verdana',
          'Monospace',
        ],
      },
      {
        name: 'size',
        acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      },
      'color',
      'background',
      'link',
    ],
  },
};

  // -- swb - I'm not dure this is necesary. I think it was useful when the Img field was updating 
  // -- but I might not care because I'm not displaying the uploaded file name in the grid. 
  



const initialState={
title: '', 
dlDesc: '', 
dlUrl: '', 
dlFilename: '',
docDesc: '', 
docUrl: '', 
docFilename: '',
descLong: '', 
addDate:  new Date(),
relDate: new Date(),
memo: '',
dispOrder: 99,
sections: [],
}

const DlAdminGrid = (props) => {
  const [download, setDownload] = useState(initialState);
  //const [rowIndex, setRowIndex] = useState(0);
  const gridInstance = useRef(null);
  //  const fileUploaderRef = useRef();
  const auth = useAuth();
  //  const headers = { Authorization: 'Bearer ' + useAuth().user.token };

  // const onUploaded = useCallback((e, cellInfo) => {
  //   const data = JSON.parse(e.request.responseText);
  //   const { path, originalname } = data;
  //   console.log('ON UPLOAD', cellInfo.item.dataField);
  //   let fieldNameFile = 'dlFilename';
  //   if (cellInfo.item.dataField === 'docUrl')
  //     fieldNameFile = 'docFilename';
  //   gridInstance.current.instance.cellValue(cellInfo.rowIndex,fieldNameFile, originalname);
  //   cellInfo.setValue(path);
  // }, []);

  // const onUploadError = useCallback((e) => {
  //   let xhttp = e.request;
  //   if (xhttp.status === 400) {
  //     e.message = e.error.responseText;
  //   }
  //   if (xhttp.readyState === 4 && xhttp.status === 0) {
  //     e.message = 'Connection refused';
  //   }
  // }, []);

  // const editCellRender = (cellInfo) => {
  //   return (
  //     <>
  //       <FileUploader
  //         ref={fileUploaderRef}
  //         multiple={false}
  //         accept="*/*"
  //         name={'file'}
  //         uploadMode="instantly"
  //         uploadHeaders={headers}
  //         uploadUrl={`${URL}/downloads/fileupload`}
  //         chunkSize={63000000}
  //         onUploadStarted
  //         onUploaded={(e) => onUploaded(e, cellInfo)}
  //         onUploadError={onUploadError}
  //       />
  //     </>
  //   );
  // };

  const sendRequest = useCallback(
    async (url, method = 'GET', data = {}) => {
      //console.log('SENDREQUEST: ', method, url, data);
      if (method === 'GET') {
        try {
          const result = await fetch(url, {
            method,
            credentials: 'include',
            headers: { Authorization: 'Bearer ' + auth.user.token },
          });
          //console.log('sendRequest RESULT', result);
          const json = await result.json();
          if (result.ok) {
            //console.log('SEND_REQUEST json.data', json);
            return json;
          }
          throw json.Message;
        } catch (err) {
          throw err.Message;
        }
      }

      const { key, values } = data;
      let params;
      if (values) {
        // send key + params. for PUT and POST
        params = {
          key,
          ...JSON.parse(values),
        };
      } else {
        // just send key, values are not included for DELETE
        params = {
          key,
        };
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
        body: JSON.stringify(params),
        credentials: 'include',
      });

      //console.log('RESPONSE', response);
      const responseData = await response.text();
      //console.log('RESPONSE Data', responseData);
      if (!response.ok) {
        throw responseData;
      }
    },
    [auth.user.token]
  );

  const loadHandler = useCallback(() => {
    const result = sendRequest(`${URL}/downloads/all`);
    //console.log('LOADHANDLER:', result);
    return result;
  }, [sendRequest]);

  const insertHandler = useCallback(
    (values) => {
      return sendRequest(`${URL}/downloads/insert`, 'POST', {
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const updateHandler = useCallback(
    (key, values) => {
      console.log('Update Handler', values);
      return sendRequest(`${URL}/downloads/update`, 'PUT', {
        key,
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const removeHandler = useCallback(
    (key) => {
      return sendRequest(`${URL}/downloads/delete`, 'DELETE', {
        key,
      });
    },
    [sendRequest]
  );

  const downloadData = useMemo(() => {
    return new CustomStore({
      key: '_id',
      load: loadHandler,
      insert: insertHandler,
      update: updateHandler,
      remove: removeHandler,
    });
  }, [loadHandler, insertHandler, updateHandler, removeHandler]);

  const loadSectionHandler = useCallback(() => {
    const result = sendRequest(`${URL}/sections/all`);
    //console.log('Sections load handler: ', result);
    return result;
  }, [sendRequest]);

  const loadFilesHandler = useCallback(() => {
    const result = sendRequest(`${URL}/files/list`);
    //console.log('Files: ', result);
    return result;
  }, [sendRequest]);

  const filesData = useMemo(() => {
    return {
      //sort: [{ selector: 'name', desc: false }],
      store: new CustomStore({
        key: 'key',
        loadMode: 'raw',
        load: loadFilesHandler,
      }),
    };
  }, [loadFilesHandler]);

  const sectionData = useMemo(() => {
    return {
      // put loadOptions here i.e  sort: [{ selector: 'name', desc: false }],
      store: new CustomStore({
        key: 'Value',
        loadMode: 'raw',
        load: loadSectionHandler,
      }),
    };
  }, [loadSectionHandler]);

  const onSelectedItemKeysChange = useCallback(
    (e, setValue) => {
      console.log(e.addedItems, e.removedItems);
      if (e.addedItems.length > 0) download.sections.push(e.addedItems[0]._id);
      if (e.removedItems.length > 0) {
        let index = download.sections.findIndex(
          (categoryID) => categoryID === e.removedItems[0]._id
        );
        download.sections.splice(index, 1);
      }
      let selectedItemKeys = e.component.option('selectedItemKeys');
      setValue(selectedItemKeys);
    },
    [download]
  );

  const onEditingStart = useCallback((e) => {
    console.log('onEditingStart', e.data);
    setDownload(e.data);
  }, []);

  // setup custom setValue event handlers for dlFilename and docFilename
  const onEditorPreparing = (e) => {
    if (
      e.parentType === 'dataRow' &&
      (e.dataField === 'dlFilename' || e.dataField === 'docFilename')
    ) {
      e.editorOptions.onValueChanged = function (ev) {
        let selectedItem = ev.component.option('selectedItem');
        e.setValue(selectedItem);
      };
    }
  };

  // custom event handler fro dlFileName - set dlUrl as well
  const setDLFilenameValue = useCallback((rowData, value) => {
    rowData.dlFilename = value.name;
    rowData.dlUrl = value.url;
  }, []);

  // custom event handler for docFileName - set docUrl as well
  const setDocFilenameValue = useCallback((rowData, value) => {
    rowData.docFilename = value.name;
    rowData.docUrl = value.url;
  }, []);

  // const onSaving = useCallback((e) => {
  //   console.log('onSaving', e.changes);
  // }, []);

  
  // editCellRender event handler for the Sections list
  const renderSectionsList = useCallback(
    (e) => {
      const $onSelectedItemKeysChange = (args) => {
        return onSelectedItemKeysChange(args, e.setValue);
      };
      //console.log('Render List')
      return (
        <List
          dataSource={sectionData}
          height={400}
          showSelectionControls={true}
          selectionMode="multiple"
          keyExpr="_id"
          displayExpr="menuDesc"
          selectedItemKeys={download.sections}
          onSelectionChanged={$onSelectedItemKeysChange}
        ></List>
      );
    },
    [download.sections, onSelectedItemKeysChange, sectionData]
  );

  return (
    <div className="App">
      <DataGrid
        id="dlGrid"
        ref={gridInstance}
        dataSource={downloadData}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        onEditorPreparing={onEditorPreparing}
        onEditingStart={onEditingStart}
        //onSaving={onSaving}
      >
        <Column dataField="title" alignment="left" colSpan={2}>
          <RequiredRule />
        </Column>
        <Column
          dataField="relDate"
          dataType="date"
          caption="Release Date"
        ></Column>
        <Column dataField="dispOrder" caption="Display Order" visible={false} />
        <Column
          dataField="dlDesc"
          visible={false}
          caption="Download Description"
        />
        <Column
          dataField="dlFilename"
          caption="Download File"
          visible={true}
          setCellValue={setDLFilenameValue}
        >
          <Lookup dataSource={filesData} valueExpr="key" displayExpr="name" />
        </Column>
        <Column
          dataField="docDesc"
          visible={false}
          caption="Document Description"
        />
        {
          <Column
            dataField="docFilename"
            caption="Document File"
            setCellValue={setDocFilenameValue}
          >
            <Lookup dataSource={filesData} valueExpr="key" displayExpr="name" />
          </Column>
        }
        <Column
          dataField="memo"
          visible={false}
          caption="Internal Notes"
          colSpan={2}
        />
        <Column
          dataField="descLong"
          caption="Detailed Descrption"
          visible={false}
          editorType="dxHtmlEditor"
          colSpan={2}
        ></Column>

        <Column
          dataField="sections"
          caption="Available to:"
          visible={false}
          editCellRender={renderSectionsList}
        ></Column>
        <ColumnFixing enabled={true} />
        <SearchPanel visible={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
        >
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="title" colSpan={2} />
              <Item dataField="dispOrder" />
              <Item dataField="relDate"></Item>
              <Item dataField="dlDesc" />
              <Item dataField="dlFilename" />
              <Item dataField="docDesc" />
              <Item dataField="docFilename" />

              <Item
                dataField="descLong"
                editorType="dxHtmlEditor"
                editorOptions={htmlEditorOptions}
                colSpan={2}
              ></Item>

              <Item
                dataField="memo"
                caption="MEMO"
                editorType="dxTextArea"
                colSpan={2}
              />
              <Item dataField="sections"></Item>
            </Item>
          </Form>
        </Editing>
      </DataGrid>
    </div>
  );
};
export default DlAdminGrid;
