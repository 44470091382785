import axios from 'axios';
import defaultUser from '../utils/default-user';

const url = process.env.REACT_APP_API_URL;


// Login
export async function signIn(email, password) {
  try {
    //console.log('Before Signin Fetch', url );
    const repsonse = await fetch( url + '/users/login',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );
    const responseData = await repsonse.json();
    //console.log('RESPONSE DATA',responseData);

    if (email) {
      return {
        isOk: true,
        data: responseData,
      };
    } else {
      return {
        isOk: false,
        message: 'Credentials not found.',
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function getUser() {
  // for now just return null to get a login prompt:
  return { isOk: false, data: null };

  // this might be where we check to see if there is a token
  // stored and see how old it is.
  // OR
  // we could have a default user who has access to the home screen and login and nothing more. 
  
  // eslint-disable-next-line
  try {
    // Send request
    return {
      isOk: {},
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(nameFirst, nameLast, company, email, password) {
  try {
    //console.log('Before createAccount Fetch');
    var result = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/signup`,
        { nameFirst, 
          nameLast, 
          company, 
          email, 
          password },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    //const responseData = await response.json();
    console.log('SIGNUP Result', result);
    //console.log('createAccount', email, password);

    return {
      isOk: true
    };
  }
  catch (err) {
    //console.log('Create EXCEPTION', err.response.data);
    return {
      isOk: false,
      message: err.response.data
    };
  }
}

export async function changePassword(password, recoveryCode) {
  try {
    //console.log(password, recoveryCode);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/changepassword`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          password,
          recoveryCode,
        }),
      }
    );

    //console.log('RESPONSE:', response.status);
    if (response.status === 401) {
      return {
        isOk: false,
        message: 'Reset link has expired please ask for new one.',
      };
    }
    return {
      isOk: response.ok,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    //console.log('Reset Password', email);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/forgotpassword`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
        }),
      }
    );

    return {
      isOk: response.ok,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
