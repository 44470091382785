import React, {useState, useEffect, useCallback } from 'react';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import './account.scss';
import {Form,
      SimpleItem,
      Label,
      ButtonItem,
      EmailRule,
      GroupItem,
    } from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';

const URL = process.env.REACT_APP_API_URL;



const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
};

const confirmPasswordEditorOptions = {
   stylingMode: 'filled',
   placeholder: 'Confirm Password',
   mode: 'password',
 };

// const initalData = {
//   nameFirst: '',
//   nameLast: '',
//   companyName: '',
//   email: '',
// };

export default function Account() {
  const auth = useAuth();
  const [user, setUser] = useState();
  //let user;


  // get the inital user record
  useEffect(() => {
    const sendRequest = async () => {
      //console.log('sendRequest before fetch');
      try {
        const response = await axios(`${URL}/users/mine`, {
          headers: { Authorization: 'Bearer ' + auth.user.token },
        });
        setUser(response.data.user);
        return;
      } catch (err) {
        throw err.Message;
      }
    };

    sendRequest();
    //eslint-disable-next-line
  },[] );

  const onDeleteHandler= async () =>{
    if (await confirm("<i>Are you sure?</i>", "Confirm Delete")){
      try {
          await axios.delete(`${URL}/users/mine`, {
          headers: { Authorization: 'Bearer ' + auth.user.token },
        });
        notify('Your account has benn removed.', 'error', 4000);
        auth.signOut();
        } catch (err) {
          //console.log(err.response.data); 
          //const message = await err.response.data.message();
          if (err.response.data === 'DELETED') {
            notify('Your account has been removed', 'error', 4000);
            auth.signOut();
          } 
        }
      } 
    };


     const onSubmitHandler = useCallback( async (e) => {
      //console.log('USER:', user);
      try {
          await axios.patch(`${URL}/users/mine`,  user,
          {
          headers: { Authorization: 'Bearer ' + auth.user.token },
        });
        notify('Your account has benn updated.', 'success', 2000);
        } catch (err) {
          //console.log(err.response.data); 
          //const message = await err.response.data.message();
          if (err.response.data === 'DELETED') {
            notify('Your account has been removed', 'error', 4000);
            auth.signOut();
          } 
        }
    // eslint-disable-next-line 
    },[user]);

  // const submitButtonOptions = {
  //   text: ' Save ',
  //   onClick: handleSubmit,
  // };
  const submitButtonOptions = {
    text: '-Save-',
    width: 200,
    onClick: onSubmitHandler,
  };
  const deleteButtonOptions = {
    text: 'Delete Account',
    width: 200,
    onClick: onDeleteHandler,
  };


  return (
    <React.Fragment>
      <h2 className={'content-block'}>Account</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          formData={user}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        >
          <GroupItem caption="Account Info">
            <SimpleItem dataField="nameFirst" isRequired={true}>
              <Label text="First Name" />
            </SimpleItem>
            <SimpleItem dataField="nameLast" isRequired={true}>
              <Label text="Last Name" />
            </SimpleItem>
            <SimpleItem dataField="email" isRequired={true}>
              <Label text="Email" />
              <EmailRule />
            </SimpleItem>
            <SimpleItem dataField="companyName" isRequired={true} />
          </GroupItem>
          <GroupItem caption="Change Password">
            <SimpleItem
              dataField="password"
              editorOptions={passwordEditorOptions}
            />
            <SimpleItem
              dataField="confirmPassord"
              editorOptions={confirmPasswordEditorOptions}
            />
          </GroupItem>
            <ButtonItem buttonOptions={submitButtonOptions} />
            <ButtonItem
              buttonOptions={deleteButtonOptions}
              onClick={onDeleteHandler}
            />
        </Form>
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2
};
