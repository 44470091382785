import React from 'react';
import './welcome.scss';
import { useAuth } from '../../contexts/auth';


const WelcomePage = () => {
  const auth = useAuth();
  const expireDate = new Date(auth.user.expireDate).toLocaleString().split(',')[0]
  //console.log(auth.user);


  return (
    <React.Fragment>
      {!auth.user.companyId && (
        <React.Fragment>
          <h2 className={'content-block'}>Welcome</h2>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <h2>
                {`Welcome to the Dash Member Site. Your company access has not been setup `}{' '}
                <br />
                <br />
                Please{' '}
                <a href="mailto:ddxSupport@dashdev.com">contact</a> Dash to complete the setup process.
              </h2>
            </div>
          </div>
        </React.Fragment>
      )}
      {auth.user.isExpired && auth.user.company && (
        <React.Fragment>
          <h2 className={'content-block'}>Welcome</h2>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <h2>
                {`Welcome to the Dash Member Site. Your support plan expired on ${expireDate} `}{' '}
                :
                <br />
                <br />
                Your user account expires at the same time that your Annual
                Support / Software Protection plan expires. Please{' '}
                <a href="mailto:ddxSupport@dashdev.com">contact</a> Dash to
                renew your Support and Maintenance Plan, or if you beleive this
                to be in error.
              </h2>
            </div>
          </div>
        </React.Fragment>
      )}

      {!auth.user.approved && (
        <React.Fragment>
          <h2 className={'content-block'}>Welcome</h2>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <h2>
                Welcome to the Dash Member Site. You have registered as:
                <br />
                <br />
                &#8226; {auth.user.name}
                <br />
                &#8226; {auth.user.company}
                <br />
                &#8226; {auth.user.email}
                <br />
                <br />
                You will receive an email from{' '}
                <a href="mailto:ddxSupport@dashdev.com">
                  ddxSupport@dashdev.com
                </a>{' '}
                when your membership has been approved.
              </h2>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default WelcomePage;