import {
  HomePage,
  AccountPage,
  DownloadPage,
  DownloadForm,
  UsersPage, 
  CompaniesPage,
  DlAdminPage, 
  SectionPage, 
  WelcomePage, 
  UploadPage,
  UserDocsPage
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';
// import React from 'react';

// const UsersPage = React.lazy(() => import('./pages'));
// const CompaniesPage = React.lazy(() => import('./pages'));
// const DlAdminPage = React.lazy(() => import('./pages'));
// const SectionPage = React.lazy(() => import('./pages'));
// const WelcomePage = React.lazy(() => import('./pages'));
// const UploadPage = React.lazy(() => import('./pages'));





const routes = [
  {
    path: '/account',
    element: AccountPage,
  },
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/download',
    element: DownloadPage,
  },
  {
    path: '/downloadForm',
    element: DownloadForm,
  },
  {
    path: '/users',
    element: UsersPage,
  },
  {
    path: '/companies',
    element: CompaniesPage,
  },
  {
    path: '/upload',
    element: UploadPage,
  },
  {
    path: '/dl-admin',
    element: DlAdminPage,
  },
  {
    path: '/section',
    element: SectionPage,
  },
  {
    path: '/welcome',
    element: WelcomePage,
  }, 
  {
    path: '/userdocs',
    element: UserDocsPage
  }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
