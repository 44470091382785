import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './SideNavigationMenu.scss';
import { useAuth } from '../../contexts/auth';


import * as events from 'devextreme/events';

export default function SideNavigationMenu(props) {
  const auth  = useAuth();
  const userAccess = auth.user.access;
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { isLarge } = useScreenSize();

  function normalizePath () {
    //console.log('access', access );
    if ( userAccess === 0) {
      return navigation
        .filter((i) => i.access === 0)
        .map((item) => ({
          ...item,
          expanded: isLarge,
          path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
        }));
      }

    if ( userAccess > 0) {
      return navigation
        .filter((i) => i.access <= userAccess && i.access > 0)
        .map((item) => ({
          ...item,
          expanded: isLarge,
          path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
        }));
    }
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e) => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }
    
    if (currentPath !== undefined) {
      // we could use getNodes to all nodes and interate looking to see if
      // currentPath does not exist and then unselect.This requires recursion
      // and recursion is complicated so I'm not interested in doing it now.

      //const nodes = treeView.getNodes();
      //console.log(nodes);
      //console.log(currentPath);

      if (currentPath === '/account') {
        // "/account" is not in the sidebar menu so unselect all
        treeView.unselectAll();
      } else {
        treeView.selectItem(currentPath);
        treeView.expandItem(currentPath);
      }
    }


    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}
