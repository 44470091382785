import React from 'react';
import './section.scss';
import SectionGrid from './SectionGrid';

const SectionPage = () => (
  <React.Fragment>
    <h2 className={'content-block'}>Sections</h2>
      <div className={'dx-card responsive-paddings'}>
        <SectionGrid />
      </div>
  </React.Fragment>
);
export default SectionPage;