import { Fragment, useCallback, useRef } from 'react';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import ScrollView from 'devextreme-react/scroll-view'
import { useAuth } from '../../contexts/auth';

//import { useAuth } from '../../contexts/auth';
//import { saveAs } from 'file-saver';

import './DownloadForm.css';

const URL = process.env.REACT_APP_API_URL;
//const DL_URL = process.env.REACT_APP_DL_URL;
// title: 1,
// dlDesc: 1,
// dlUrl: 1,
// docDesc: 1,
// docUrl: 1,
// descLong: 1,
// relDate: 1,



const DownloadForm = (props) => {
  const {
    title,
    dlDesc,
    _id, 
    dlUrl,
    dlFilename,
    docUrl,
    docFilename,
    docDesc,
    descLong,
  } = props;

  const scrollViewRef = useRef(null);
  const auth = useAuth();
  //const auth = useAuth();
  
  
  // This works but right now offers no advantage over the method used in the JSX code 
  // const doDownload = async () => {
  //   const downloadUrl = await getDownload(props._id);
  //   console.log('DODOWNLOAD', `${downloadUrl}`);

  //   if (downloadUrl) {
  //     //const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = `${downloadUrl}`;
  //     link.setAttribute('download', props.dlFilename);
  //     document.body.appendChild(link);
  //     link.click();
  //     }
  //   };

  //  const getDownload = async (id) => {
  //   console.log('getDownload', id) 
  //   let response;
  //     try {
  //       response = await fetch(
  //         `${URL}/downloads/getDownload?` + new URLSearchParams({ id: id }),
  //         {
  //           method: 'GET',
  //           headers: {
  //             Authorization: 'Bearer ' + auth.user.token,
  //             'Content-Type': 'JSON',
  //           },
  //         }
  //       );
  //       const json = await response.json();
  //       console.log('GetDownload', json.download);
  //       return json.download;
  //     } catch (err) {
  //       console.log(err.message); 
  //     }
  //  };
  
  //console.log('PROPS', props);
  const postHistory = useCallback(
    async (fileName) => {
      console.log('onDownloadClick');
      try {
        await axios.post(
          `${URL}/history/insert`,
          {
            downloadId: _id,
            fileName: fileName,
          },
          {
            headers: { Authorization: 'Bearer ' + auth.user.token },
          }
        );
      } catch (err) {
        throw err.Message;
      }
    },
    [_id,auth.user.token]
  );

  const onDlFileClick = () => {
    postHistory(dlFilename); 
  }
  const onDocFileClick = () =>{
    postHistory(docFilename);
  };
  
    return (
      <Fragment>
        <div className="download-container">
          <div className="download-title">
            <h2>{title}</h2>
            <p>{dlDesc}</p>
          </div>
          {descLong && (
            <div>
              <ScrollView
                id="scrollview"
                ref={scrollViewRef}
                reachBottomText="Updating..."
                scrollByContent={true}
                bounceEnabled={false}
                //onReachBottom={updateBottomContent}
                showScrollbar="onHover"
                scrollByThumb={true}
              >
                <div
                  className="html-content"
                  dangerouslySetInnerHTML={{ __html: descLong }}
                ></div>
              </ScrollView>
            </div>
          )}
          <div>
            {/* <Button className="download-button" onClick={doDownload}>
            Get Download
          </Button> */}
            {dlUrl && dlFilename && (
              <a
                className="download-a"
                href={`${dlUrl}`}
                download={`${dlFilename}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="download-button" onClick={onDlFileClick}>
                  {`Download ${dlDesc}`}{' '}
                </Button>
              </a>
            )}
            {docUrl && docFilename && (
              <a
                className="download-a"
                href={`${docUrl}`}
                download={`${docFilename}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="download-button"
                  onClick={onDocFileClick}
                >{`Download ${docDesc}`}</Button>
              </a>
            )}
          </div>
          <div>
            <Button className="download-button" onClick={props.onBackClick}>
              &lt; Cancel
            </Button>
          </div>
        </div>
      </Fragment>
    );
};
export default DownloadForm;
