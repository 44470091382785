import { Fragment } from 'react';
import DlAdminGrid from './DlAdminGrid';
import './dl-admin.scss';

// eslint-disable-next-line
const DlAdmin = () => (
  <Fragment>
    <div className={'dx-card responsive-paddings'}>
      <DlAdminGrid />
    </div>
  </Fragment>
);
export default DlAdmin 
