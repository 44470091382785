import { useMemo, useCallback, useState } from 'react';
import {
  DataGrid,
  Column,
  ColumnFixing,
  FilterRow,
  SearchPanel,
  GroupPanel,
  RequiredRule,
  Editing,
  Form,
  Item,
} from 'devextreme-react/data-grid';
import List from 'devextreme-react/list';
import CustomStore from 'devextreme/data/custom_store';
import { useAuth } from '../../contexts/auth';

const URL = process.env.REACT_APP_API_URL;

const initialState={
  name: '',
  expirationDate: new Date(),
  memo: '',
  sections: [],
}

const CompanyGrid = (props) => {
  const [company, setCompany] = useState(initialState);
  const auth = useAuth();
  const sendRequest = useCallback(
    async (url, method = 'GET', data = {}) => {
      //console.log('SENDREQUEST: ', method, url, data);
      if (method === 'GET') {
        try {
          const result = await fetch(url, {
            method,
            credentials: 'include',
            headers: { Authorization: 'Bearer ' + auth.user.token },
          });
          //console.log('sendRequest RESULT', result);

          const json = await result.json();
          //console.log('sendRequest RESULT', json);
          if (result.ok) {
            return json;
          }
          throw json.Message;
        } catch (err) {
          throw err.Message;
        }
      }

      const { key, values } = data;
      let params;
      if (values) {
        // send key + params. for PUT and POST
        params = {
          key,
          ...JSON.parse(values),
        };
      } else {
        // just send key, values are not included for DELETE
        params = {
          key,
        };
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
        body: JSON.stringify(params),
        credentials: 'include',
      });

      //console.log('RESPONSE', response);
      const responseData = await response.text();
      //console.log('RESPONSE Data', responseData);
      if (!response.ok) {
        throw responseData;
      }
    },
    [auth.user.token]
  );

  const loadHandler = useCallback(() => {
    const result = sendRequest(`${URL}/companies/all`);
    //console.log('LOADHANDLER:', result);
    return result;
  }, [sendRequest]);

  const insertHandler = useCallback(
    (values) => {
      return sendRequest(`${URL}/companies/insert`, 'POST', {
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const updateHandler = useCallback(
    (key, values) => {
      //console.log('Update Handler', values);
      return sendRequest(`${URL}/companies/update`, 'PUT', {
        key,
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const removeHandler = useCallback(
    (key) => {
      return sendRequest(`${URL}/companies/delete`, 'DELETE', {
        key,
      });
    },
    [sendRequest]
  );

  const companyData = useMemo(() => {
    return new CustomStore({
      key: '_id',
      load: loadHandler,
      insert: insertHandler,
      update: updateHandler,
      remove: removeHandler,
    });
  }, [loadHandler, insertHandler, updateHandler, removeHandler]);
  
  const loadSectionHandler = useCallback(() => {
     const result = sendRequest(`${URL}/sections/all`);
     //console.log('Sections Load Handler: ', result);
     return result;
  }, [sendRequest]);

  const sectionData = useMemo(() => {
     return {
       // put loadOptions here i.e  sort: [{ selector: 'name', desc: false }],
       store: new CustomStore({
         key: 'Value',
         loadMode: 'raw',
         load: loadSectionHandler,
       }),
     };
   }, [loadSectionHandler]);

   const onSelectedItemKeysChange = useCallback( (e, setValue) => {
      //console.log(e.addedItems, e.removedItems);
       if (e.addedItems.length > 0) company.sections.push(e.addedItems[0]._id);
       if (e.removedItems.length > 0) {
         let index = company.sections.findIndex(
           (categoryID) => categoryID === e.removedItems[0]._id
         );
         company.sections.splice(index, 1);
       }
      let selectedItemKeys = e.component.option('selectedItemKeys');
      setValue(selectedItemKeys);
      },
     [company]
   );  

  const onEditingStart = useCallback((e) =>{
    //console.log('onEditingStart', e.data);    
    setCompany(e.data);
  },[])

  // const onSaving = useCallback((e) => {
  //   console.log('onSaving', e.changes);
  // },[])

  const renderList = useCallback(
    (e) => {
      const $onSelectedItemKeysChange = (args) => {
        return onSelectedItemKeysChange(args, e.setValue);
      };
      return (
        <List
          dataSource={sectionData}
          height={400}
          showSelectionControls={true}
          selectionMode="multiple"
          keyExpr="_id"
          displayExpr="menuDesc"
          selectedItemKeys={company.sections}
          onSelectionChanged={$onSelectedItemKeysChange}
        ></List>
      );
    },
    [company.sections, onSelectedItemKeysChange, sectionData]
  );

  return (
    <div className="App">
      <DataGrid
        dataSource={companyData}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        onEditingStart={onEditingStart}
        //onSaving={onSaving}
      >
        <Column dataField="name" fixed={true}>
          <RequiredRule />
        </Column>
        <Column dataField="expirationDate" dataType="date"></Column>
        <Column dataField="memo" visible={false}></Column>
        <Column dataField="sections" visible={false} editCellRender={renderList}>
        </Column>

        <ColumnFixing enabled={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <GroupPanel visible={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
        >
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="name" caption="Company:" />
              <Item dataField="expirationDate" />
              <Item dataField="sections"></Item>
              <Item dataField="memo" editorType="dxTextArea" colSpan={2} />
            </Item>
          </Form>
        </Editing>
      </DataGrid>
    </div>
  );
};
export default CompanyGrid;
