import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
//import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import {SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

const Content = () => {
  const auth = useAuth();
  let path = '/home';
  if (auth.user.access === 0) {
    path = '/welcome';
  } 


  return (
    <SideNavBarLayout title={appInfo.title}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate to={`${path}`} />} />
        </Routes>
      <Footer>
        Copyright © 1998-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </SideNavBarLayout>
  );
}
export default Content;
