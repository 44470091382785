import { Fragment } from 'react';
import './companies.scss';
import CompanyGrid from './CompanyGrid';


const Companies = () => (
  <Fragment>
    <div className={'dx-card responsive-paddings'}>
      <CompanyGrid />
    </div>
  </Fragment>
);
export default Companies;