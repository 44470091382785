import { Fragment, useMemo, useCallback, useState } from 'react';
import './download.scss';
import DataGrid, {Column} from 'devextreme-react/data-grid';
//import Form,  {Item} from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import { useAuth } from '../../contexts/auth';
import DownloadForm from './DownloadForm';
import  axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line
 const DownloadPage = () => {
  const [selectedDownload, setSelectedDownload] = useState();
  const [showingDownload, setShowingDownload] = useState(false);
  //const [downloadId, setDownloadId] = useState('');

  const auth = useAuth();
  
  const sendRequest = useCallback(
    async (url, method = 'GET', data = {}) => {
      try {
        const result = await axios.get(url, {
          credentials: 'include',
          headers: { Authorization: 'Bearer ' + auth.user.token },
        });
        if (result.status === 200) {
          //console.log('RESULTS', result.data );
          return( result );
        }
        throw result;
      } catch (err) {
        throw err.response.data;
      }
    },
    [auth.user.token]
  );

  const loadHandler = useCallback( async () => {
    const result = await sendRequest(`${URL}/downloads/mine`)
    //console.log('DOWNLOAD LOADHANDLER:' , result);
    return result.data; 
  }, [sendRequest]);

  const downloadData = useMemo(() => {
    return new CustomStore ({
      key: '_id',
      load: loadHandler
    });
  }, [loadHandler]);

  const onRowClick = (e) => {
    setSelectedDownload(e.data);
    setShowingDownload(true);
  };
  const onBackClick = () =>{ 
    setShowingDownload(false);
  }

  const dataErrorOccurred = (e) => {
    console.log('onDataErrorOccurred', e.error);
  };



  return (
    <Fragment>
      {showingDownload && selectedDownload && (
        <Fragment>
          <DownloadForm
            title={selectedDownload.title}
            dlDesc={selectedDownload.dlDesc}
            _id={selectedDownload._id}
            dlUrl={selectedDownload.dlUrl}
            dlFilename={selectedDownload.dlFilename}
            docDesc={selectedDownload.docDesc}
            docUrl={selectedDownload.docUrl}
            docFilename={selectedDownload.docFilename}
            descLong={selectedDownload.descLong}
            relDate={selectedDownload.relDate}
            onBackClick={onBackClick}
          />
        </Fragment>
      )}
      {!showingDownload && (
        <Fragment>
          <h2 className={'content-block'}>Downloads</h2>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <DataGrid
                dataSource={downloadData}
                onRowClick={onRowClick}
                onDataErrorOccurred={dataErrorOccurred}
              >
                <Column dataField="title" width= '80%' />
                <Column
                  dataField="relDate"
                  caption="Release Date"
                  dataType="date"
                />
              </DataGrid>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
 };
export default DownloadPage;
