import { useCallback, useMemo } from 'react';
import DataGrid, {  
      Column, 
      ColumnFixing, 
      SearchPanel, 
      Editing, 
      Form,  
      Lookup,
      Item
    } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import CustomStore from 'devextreme/data/custom_store';
import {accessData} from './accessLevels';
import { useAuth } from '../../contexts/auth';
//import { useFetch } from '../../hooks/use-fetch';
const URL = process.env.REACT_APP_API_URL;



const UserGrid = (props) => {
  const auth = useAuth();  
  const sendRequest = useCallback(
    async (url, method = 'GET', data = {}) => {
      if (method === 'GET') {
        try {
          const result = await fetch(url, {
            method,
            credentials: 'include',
            headers: { Authorization: 'Bearer ' + auth.user.token },
          });
          const json = await result.json();
          if (result.ok) {
            return json;
          }
          throw json.Message;
        } catch (err) {
          throw err.Message;
        }
      }

      const { key, values } = data;
      let params;
      if (values) {
        // send key + params. for PUT and POST
        params = {
          key,
          ...JSON.parse(values),
        };
      } else {
        // just send key, values are not included for DELETE
        params = {
          key,
        };
      }

      //const result_2 = await fetch(url, {
        const response = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.user.token,
          },
          body: JSON.stringify(params),
          credentials: 'include',
        });

        //console.log('RESPONSE', response);
        const responseData = await response.text();
        //console.log('RESPONSE Data', responseData);
        if (!response.ok) {
          throw responseData;
        }

      // console.log('result_2', result_2);
      // if (result_2.ok) {
      //   return result_2.text().then((text) => text && JSON.parse(text));
      // }
      // const json_1 = await result_2.json();
      // console.log('json_1', json_1);
      // throw json_1.Message;
    },
    [auth.user.token]
  );
  
  const loadHandler = useCallback(() => {
    const result = sendRequest(`${URL}/users/all`); 
    //console.log('LOADHANDLER:' , result );
    return result 
  }, [sendRequest]);

  const insertHandler = useCallback(
    (values) => {
      return sendRequest(`${URL}/users/insert`, 'POST', {
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const updateHandler = useCallback(
    (key, values) => {
      return sendRequest(`${URL}/users/update`, 'PUT', {
        key,
        values: JSON.stringify(values),
      });
    },
    [sendRequest]
  );

  const removeHandler = useCallback(
    (key) => {
      return sendRequest(`${URL}/users/delete`, 'DELETE', {
        key,
      });
    },
    [sendRequest]
  );

  const loadCompanyHandler = useCallback(() => {
    const result = sendRequest(`${URL}/companies/all`);
    //console.log('Companies: ', result);
    return result;
  }, [sendRequest]);

  const companyData = useMemo(() => {
    return {
      sort: [{ selector: 'name', desc: false }],
      store: new CustomStore({
        key: 'Value',
        loadMode: 'raw',
        load: loadCompanyHandler
      }),
    }
  },[loadCompanyHandler]);

  const userData = useMemo(() => {
    return new CustomStore({
      key: '_id',
      load: loadHandler,
      insert: insertHandler,
      update: updateHandler,
      remove: removeHandler,
    });
  }, [loadHandler, insertHandler, updateHandler, removeHandler]);


  return (
    <div>
      <DataGrid
        dataSource={userData}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
      >
        <Editing
          refreshMode={'full'}
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
       >
          <Form >
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="nameLast" caption="Last:" />
              <Item dataField="nameFirst" caption="First:" />
              <Item dataField="companyName" caption="Company:" />
              <Item dataField="companyId" caption="CompamyId"/>
              <Item dataField="email" caption="Email:" />
              <Item dataField="access" />
              <Item dataField="memo" editorType="dxTextArea" colSpan={2} />
            </Item>
          </Form>
        </Editing>
        <Column dataField="nameLast" />
        <Column dataField="nameFirst" />
        <Column dataField="companyName" />
        <Column dataField="companyId">
          <Lookup dataSource={companyData} valueExpr="_id" displayExpr="name" /> 
        </Column>
        <Column dataField="email" />
        <Column dataField="access" caption="Access" width={125}>
          <Lookup dataSource={accessData} valueExpr="ID" displayExpr="Desc" />
        </Column>
        <Column dataField="memo" visible={false} />
        <ColumnFixing enabled={true} />
        <SearchPanel visible={true} />
      </DataGrid>
    </div>
  );
};
 export default UserGrid;